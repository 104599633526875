import 'react-app-polyfill/ie11'; //St�tte for IE11
import 'react-app-polyfill/stable'; //St�tte for IE11
import React from 'react';
import ReactDOM from 'react-dom';
import './CSS/Main.css';
import { UserProvider } from './Components/StateContext/UserContext';
import { ToastContainer } from 'react-toastify';

import * as Sentry from "@sentry/browser";
import App from './App';

Sentry.init({ dsn: "https://e42284bc6f484d66b2f7209e8f939ca8@sentry.gressquel.com/1" });
ReactDOM.render(
    <UserProvider>
      <ToastContainer containerId="MainToast" draggable={false} />
      <App /> {}
    </UserProvider>,
    document.getElementById('root')
  );