import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext, useUserContext } from '../Components/StateContext/UserContext';
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom';


const FrontpageView: React.FC<RouteComponentProps> = (props) => {
  const { depId, classId, week } = useParams<{ depId: string; classId: string; week: string }>();
  const { user, department, userRoles } = useUserContext();


  useEffect(() => {
  }, [depId, classId, week, department]);


  const btnSaveClicked = () => {
    // setUserId(123);
  };

  return (
    <>
      <div className="ods-grid ods-grid--gap-row-large ods-margin-top-25 ods-text--align-center">

        <div className="ods-grid__column--12">
          <div className="ods-grid ods-grid--center">
           
            <div className="ods-grid__column--4">
                <a href={`/classes/${department?.Id}`} className="o-kom-link">
                  <span className="ods-icon ods-icon--size-kilo ods-icon--size-echo-breakpoint-medium ods-icon--success-square" aria-hidden="true"></span>
                  <span className="ods-text--size-kilo ods-text--size-echo-breakpoint-medium">Fravær</span>
                </a>
            </div>

            <div className="ods-grid__column--4">
            <a href={`/usersearch/${department?.Id}`} className="o-kom-link">
              <span className="ods-icon ods-icon--size-kilo ods-icon--size-echo-breakpoint-medium ods-icon--magnifying-glass-big" aria-hidden="true"></span>
              <span className="ods-text--size-kilo ods-text--size-echo-breakpoint-medium">Brukersøk</span>
              </a>
            </div>

            <div className="ods-grid__column--4">
              <a href={`/myuserdetails`} className="o-kom-link">
                <span className="ods-icon ods-icon--size-kilo ods-icon--size-echo-breakpoint-medium ods-icon--user" aria-hidden="true"></span>
                <span className="ods-text--size-kilo ods-text--size-echo-breakpoint-medium">Min side</span>
              </a>
            </div>
            
          </div>
        </div>
      </div>

      <div className="ods-grid ods-grid--gap-row-large ods-margin-top-25 ods-text--align-center">
        <div className="ods-grid__column--12">
          <div className="ods-grid ods-grid--center">
            <div className="ods-grid__column--4 ods-color-text-grayscale-10">
              <span className="ods-icon ods-icon--size-kilo ods-icon--size-echo-breakpoint-medium ods-icon--message" aria-hidden="true"></span><span className="ods-text--size-kilo ods-text--size-echo-breakpoint-medium">Rapporter</span>
            </div>

            <div className="ods-grid__column--4">
            <a href={`/roombooking/${department?.Id}`} className="o-kom-link">
              <span className="ods-icon ods-icon--size-kilo ods-icon--size-echo-breakpoint-medium ods-icon--calendar" aria-hidden="true"></span><span className="ods-text--size-kilo ods-text--size-echo-breakpoint-medium">Rom booking</span>
              </a>
            </div>
            <div className="ods-grid__column--4">
            <a href={`/createuser/${department?.Id}`} className="o-kom-link">
              <span className="ods-icon ods-icon--size-kilo ods-icon--size-echo-breakpoint-medium ods-icon--plus-sign" aria-hidden="true"></span><span className="ods-text--size-kilo ods-text--size-echo-breakpoint-medium">Opprett bruker</span>
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="ods-grid ods-grid--gap-row-large ods-margin-top-25 ods-text--align-center">
        <div className="ods-grid__column--12">
          <div className="ods-grid ods-grid--center">
            {(userRoles.includes("localadmin")  || userRoles.includes("admin")) &&
              <div className="ods-grid__column--4">
                <a href={`/payments/${department?.Id}`} className="o-kom-link">
                  <span className="ods-icon ods-icon--size-kilo ods-icon--size-echo-breakpoint-medium ods-icon--coin-stacks" aria-hidden="true"></span><span className="ods-text--size-kilo ods-text--size-echo-breakpoint-medium">Betalinger</span>
                </a>
              </div>
            }
          </div>
        </div>
      </div>

    </>
  );
}

export default withRouter(FrontpageView);
