

export class AbstractResponse {
	Success: boolean;
	Message: string;
	ErrorLog: string[];
}

export class RolesHelper {
	static readonly Administrator = "admin";
	static readonly LocalAdministrator = "localadmin";
	static readonly Teacher = "teacher";
	static readonly Parent = "parent";
	static readonly Student = "student";
}

export class CachedSearch {
	Result: Api.SearchResult[];
	Query: string;
}


export class PaymentViews {
	static readonly All = "all";
	static readonly Membership = "membership";
}