import React, { useEffect, useState } from 'react';

import myconfig from '../Config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tippy/dist/tippy.css'
import {
  Tooltip,
} from 'react-tippy';
 
import OKomWeekPagination from '../Components/OsloKommuneUI/OKomWeekPagination';
import OKomCheckbox from '../Components/OsloKommuneUI/OKomCheckbox';
import OKomTextField from '../Components/OsloKommuneUI/OKomTextField';
import OKomButton from '../Components/OsloKommuneUI/OKomButton';
import { UserContext, useUserContext } from '../Components/StateContext/UserContext';
import { RouteComponentProps, withRouter, useParams, useHistory } from 'react-router-dom';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb';
import RestService from '../Services/RestService';
import { AbstractResponse } from '../Models/Interfaces';


const AttendanceView: React.FC<RouteComponentProps> = (props) => {
  const { classId, week } = useParams<{ classId: string; week: string }>();
  const { user } = useUserContext();
  const [attendances, setAttendances] = useState<Api.AttendanceExtended[]>([]);
  const [updateTimestamp, setUpdateTimestamp] = useState<Date>(new Date());
  const [changedAttendances, setChangedAttendances] = useState<{ [key: number]: Api.AttendanceExtended }>({});
  const history = useHistory();

  useEffect(() => {
    RestService.get<Api.AttendanceExtended[]>(`/api/attendance/GetAttendanceByAttendanceDate?weekNo=${week}&classId=${classId}`)
      .then(response => {
        setAttendances([...response]);
      })

  }, [classId, week]);


  useEffect(() => {
    setUpdateTimestamp(new Date());
  }, [attendances]);


  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const updates = Object.values(changedAttendances).map(attendance => {
        const update: Api.AttendanceInsertOrUpdateModel = {
          ClassId: parseInt(classId),
          StudentId: attendance.StudentId,
          DidAttend: attendance.DidAttend,
          Comment: attendance.Comment,
          WeekNo: parseInt(week)
      };
      return update;
      });

      if (updates.length > 0) {
        event.preventDefault(); 
        event.returnValue = ''; 
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [changedAttendances]);

  const handleAttendanceChange = (studentId: number,  didAttend: boolean) => {
    setChangedAttendances(prev => ({
      ...prev,
      [studentId]: { ...prev[studentId], DidAttend: didAttend, StudentId : studentId}
    }));
  };

  const handleCommentChange = (studentId: number, comment: string) => {
    console.log(`Comm: ${comment}`);
    setChangedAttendances(prev => ({
      ...prev,
      [studentId]: { ...prev[studentId], Comment: comment, StudentId : studentId }
    }));
  };

  const submitAttendanceUpdates = async () => {
    const updates = Object.values(changedAttendances).map(attendance => {
      const update: Api.AttendanceInsertOrUpdateModel = {
        ClassId: parseInt(classId),
        StudentId: attendance.StudentId,
        DidAttend: attendance.DidAttend,
        Comment: attendance.Comment,
        WeekNo: parseInt(week)
    };
    return update;
    });
  
    if (updates.length > 0) {
      try {
        var response = await RestService.post<Api.AttendanceInsertOrUpdateModel[], AbstractResponse>('/api/attendance/InsertOrUpdateAttendance', updates);
        if(response.Success){
          setChangedAttendances([]);
          toast.success('Oppdatert!');
        }
        else toast.error(`Noe gikk galt: ${response.Message}`);
        
      } catch (error) {
        console.error('Noe gikk galt:', error);
        toast.error(`Noe gikk galt: ${error}`);
      }
    } else {
      toast.info('Ingen endringer å lagre');
    }
  };
  

  const onWeekClick = (_week: number) => {
    history.push(`/attendance/${classId}/${_week}`);
  };


  return (
    <>
      <div>
        <div className="ods-grid">
          <OKomBreadcrumb path='Klasseoversikt, Fraværregistrering'></OKomBreadcrumb>

          <div className="ods-grid__column--12">
            <div className="ods-grid ods-grid--gutter">
              <div className="ods-grid__column--12">
                <div className="ods-grid ods-grid--gap-row-large ods-grid--center">

                  <div className="ods-grid__column--12">
                    <div className="ods-color-bg-blue-lnight">
                      <h4 className="ods-text--size-india ods-text--size-charlie-breakpoint-medium ods-text--size-charlie-breakpoint-large">Fraværsoversikt</h4>

                    </div>
                  </div>

                  <div className="ods-grid__column--12">
                    <div className="ods-text--align-center ods-color-bg-blue-lnight ods-padding-vertical-5">
                      Uke
                      <OKomWeekPagination selectedDefaultWeek={parseInt(week)} onWeekClick={onWeekClick}></OKomWeekPagination>
                    </div>
                  </div>

                  <div className="ods-grid__column--12">
                    <div className="ods-grid ods-grid--gap">
                      <div className="ods-grid__column--12">
                        <table className="ods-table ods-table--full-width">
                          <thead className="ods-table__head">
                            <tr className="ods-table__row">
                              <th scope="col" className="ods-table__heading">Elev</th>
                              <th scope="col" className="ods-table__heading">Tilstede?</th>
                              <th scope="col" className="ods-table__heading">Annet</th>
                            </tr>
                          </thead>
                          <tbody className="ods-table__body" key={updateTimestamp.toISOString()}>

                              {attendances.map((attendance, index) => (
                                <tr key={index} className="ods-table__row">
                         
                                  <th scope="col" className="ods-table__heading"><a target='_blank' href={`/userdetails/${attendance.StudentId}/student`}>{attendance.Student}</a> 
                                    {(attendance.Note != null && attendance.Note != "") &&
                                      <Tooltip
                                        title={attendance.Note}
                                        position="top"
                                        trigger="mouseenter"
                                      >
                                       <span  className="ods-icon ods-icon--size-Kilo ods-icon--exclamation-mark-circle ods-icon--color-red ods-margin-left-3" aria-hidden="true"></span>
                                      </Tooltip>
                                    }
                                  </th>
                                  <td className="ods-table__cell">
                                    <OKomCheckbox
                                      checked={attendance.DidAttend}
                                      onChange={(e) => handleAttendanceChange(attendance.StudentId, e)}
                                    />
                                  </td>
                                  <td className="ods-table__cell">
                                    <OKomTextField
                                      text={attendance.Comment ?? ""}
                                      onChange={(e) => handleCommentChange(attendance.StudentId, e.target.value)}
                                      placeholderText='Forsentkomning eller annet'
                                    />
                                  </td>
                                </tr>
                              ))}
                          
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  <div className="ods-grid__column--12">
                    <div className="ods-grid ods-grid--gap">
                      <div className="ods-grid__column--1 ods-grid__column--start-0 ods-grid__column--end-13-breakpoint-medium">
                        <div className="ods-padding-vertical-3">
                          <OKomButton buttonText='Lagre' onClick={submitAttendanceUpdates}> </OKomButton>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default withRouter(AttendanceView);
