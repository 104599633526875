import React from 'react';

interface OKomTextFieldProps {
    text? : string;
}

const OKomLoading: React.FC<OKomTextFieldProps> = ({ text }) => {
  
    return (
        <div className="ods-loading" aria-live="polite">{text ? text : "Laster inn, vent..."}</div>
    );
};

export default OKomLoading;

