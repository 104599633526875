import React from 'react';
import OKomButton from './OKomButton';

interface OKomSearchResultCardProps {
    children?: React.ReactNode;
    header?: string;
    footer?: string;
    onShowMoreClicked?: () => void;
}

const OKomSearchResultCard: React.FC<OKomSearchResultCardProps> = ({ onShowMoreClicked, children, header, footer }) => {

    return (
        <div>
            <div className="ods-status-card">
                <div className="ods-status-card__content">
                    <h3 className="ods-status-card__heading">{header}</h3>
                    <p className="ods-status-card__text">{children}</p>
                    <div className="ods-status-card__actions">
                        <OKomButton onClick={onShowMoreClicked} buttonText='Vis mer' color='blue' size='small'></OKomButton>
                    </div>
                </div>
                <div className="ods-status-card__footer">{footer}</div>
            </div>
        </div>
    );
};

export default OKomSearchResultCard;

