import React, { useState } from 'react';

type OKomTabProps = {
  tabs: string[];
  onTabChange: (activeTab: string) => void;
};

const OKomTab: React.FC<OKomTabProps> = ({ tabs, onTabChange }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);

    const buttonStyleActive: React.CSSProperties = {
        backgroundColor: '#1f42aa',
        border: '2px solid #1f42aa',
        textDecoration: 'underline'
    };

  const buttonStyle: React.CSSProperties = {
  
  };


  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    onTabChange(tab);
  };

  return (
    <div>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`ods-button ${activeTab === tab ? '' : 'ods-button--text'}`}
          onClick={() => handleTabClick(tab)}
          style={activeTab === tab ? buttonStyleActive : buttonStyle}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default OKomTab;
