import React, { useState } from 'react';
import DateHelperUtil from '../../Utils/DateHelperUtil';


interface OKomWeekPaginationProps {
  selectedDefaultWeek?: number;
  onWeekClick?: (week: number) => void;
}

const OKomWeekPagination: React.FC<OKomWeekPaginationProps> = ({ selectedDefaultWeek, onWeekClick }) => {
  const [selectedWeek, setSelectedWeek] = useState<number>(selectedDefaultWeek || DateHelperUtil.getWeekNumber());
  const [visibleWeeks, setVisibleWeeks] = useState<number[]>(getVisibleWeeks(selectedWeek));

  function getVisibleWeeks(currentWeek: number): number[] {
    let weeks: number[] = [];
    if (currentWeek <= 5) {
      for (let i = 1; i <= 11; i++) {
        weeks.push(i);
      }
    } else if (currentWeek >= 48) {
      for (let i = 42; i <= 52; i++) {
        weeks.push(i);
      }
    } else {
      for (let i = currentWeek - 5; i <= currentWeek + 5; i++) {
        weeks.push(i);
      }
    }
    return weeks;
  }
  
  const handleClickWeek = (week: number) => {
    setSelectedWeek(week);
    if (onWeekClick) {
      onWeekClick(week);
    }
  };

  const handlePrevious = () => {
    const newWeek = Math.max(1, selectedWeek - 1);
    setSelectedWeek(newWeek);
    setVisibleWeeks(getVisibleWeeks(newWeek));
    if (onWeekClick) {
      onWeekClick(newWeek);
    }
  };

  const handleNext = () => {
    const newWeek = Math.min(52, selectedWeek + 1);
    setSelectedWeek(newWeek);
    setVisibleWeeks(getVisibleWeeks(newWeek));
    if (onWeekClick) {
      onWeekClick(newWeek);
    }
  };

  return (
    <nav className="ods-pagination" aria-label="Week pagination">
      <a className="ods-pagination__previous" aria-label="Show previous 5 weeks" onClick={handlePrevious}></a>
      {visibleWeeks.map((week) => (
        <a 
          key={week} 
          className={`ods-pagination__item ${week === selectedWeek ? 'ods-pagination__item--current' : ''}`} 
          onClick={() => handleClickWeek(week)}
        >
          <span className="ods-sr-only">Go to week {week}</span>
          <span aria-hidden="true">{week}</span>
        </a>
      ))}
      <a className="ods-pagination__next" aria-label="Show next 5 weeks" onClick={handleNext}></a>
    </nav>
  );
};

export default OKomWeekPagination;
