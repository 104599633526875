import React from 'react';

interface OKomBreadcrumbProps {
    path: string;
}

const OKomBreadcrumb: React.FC<OKomBreadcrumbProps> = ({ path }) => {
    const items = path.split(',').map((item, index) => (
        <li key={index} className="ods-breadcrumb__item">
            {item.trim() === 'Klasseoversikt' ? (
                <a className="ods-breadcrumb__link" href="/classes/1">{item.trim()}</a>
            ) : (
                <span className="ods-breadcrumb__link">{item.trim()}</span>
            )}
        </li>
    ));

    return (
        <div className="ods-grid__column--12 ods-padding-6">
            <nav className="ods-breadcrumb" aria-label="Breadcrumb">
                <ul className="ods-breadcrumb__list">
                    <li className="ods-breadcrumb__item">
                        <a className="ods-breadcrumb__link" href="/">Hjem</a>
                    </li>
                    {items}
                </ul>
            </nav>
        </div>
    );
};

export default OKomBreadcrumb;
