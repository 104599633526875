import React, { useState } from 'react';

interface OKomCheckboxProps {
    checked: boolean;
    children?: React.ReactNode;
    onChange?: (checked: boolean) => void; 
}

const OKomCheckbox: React.FC<OKomCheckboxProps> = ({ onChange, children, checked }) => {
    // Local state for the checkbox checked status
    const [isChecked, setIsChecked] = useState(checked);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Update local state
        setIsChecked(e.target.checked);

        // Call the onChange prop to notify the parent component
        if (onChange) {
            onChange(e.target.checked);
        }
    };

    return (
        <div>
            <label className="ods-checkbox">
                <input 
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <span className="ods-checkbox__checkmark"></span>
                <span className="ods-checkbox__text">{children}</span>
            </label>
        </div>
    );
};

export default OKomCheckbox;
