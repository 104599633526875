import { RolesHelper } from "../Models/Interfaces";

export function mapRoleToDisplayName(role: string): string {
    const roleMappings: { [key: string]: string } = {
      student: 'Elev',
      teacher: 'Lærer',
      normal: 'Vanlig bruker',
      admin: 'Admin',
      parent: 'Forelder',
    };
  
    const roleLowerCase = role.toLowerCase();
    return roleMappings[roleLowerCase] || role;
  }
  

  export function isAdmiOrLocalAdminOrTeacher(roles: string[]): boolean {
    if(roles==null) return false;
    const isAdmin = roles.includes(RolesHelper.Administrator);
    const isTeacher = roles.includes(RolesHelper.Teacher);
    const isLocalAdmin = roles.includes(RolesHelper.LocalAdministrator);
  
    return isAdmin || isTeacher || isLocalAdmin;
  }

  export function isAdminOrLocalAdmin(roles: string[]): boolean {
    if(roles==null) return false;
    const isAdmin = roles.includes(RolesHelper.Administrator);
    const isLocalAdmin = roles.includes(RolesHelper.LocalAdministrator);
  
    return isAdmin || isLocalAdmin;
  }