import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUserContext } from '../Components/StateContext/UserContext';
import { useParams } from 'react-router-dom';
import RestService from '../Services/RestService';
import moment from 'moment';
import OKomButton from '../Components/OsloKommuneUI/OKomButton';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb';
import { isAdminOrLocalAdmin, isAdmiOrLocalAdminOrTeacher, mapRoleToDisplayName } from '../Utils/RoleMappingUtil';
import { RolesHelper } from '../Models/Interfaces';
import { PhoneSearch } from '../Components/OsloKommuneUI/OKomNewUserComponent';

const UserDetailsView: React.FC = () => {
  const { userid, usertype } = useParams<{ userid: string; usertype: string }>();
  const { department, setUserInfo, userToken, userRoles } = useUserContext();
  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [note, setNote] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [mobilePhone, setMobilePhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [_department, setDepartment] = useState<string>();
  const [roles, setRoles] = useState<string[]>();
  const [parents, setParents] = useState<number[]>();
  const [lookupUser, setLookupUser] = useState<Api.User>();
  const [parent1, setParent1] = useState<PhoneSearch>(new PhoneSearch());
  const [parent2, setParent2] = useState<PhoneSearch>(new PhoneSearch());
  const [addParent1, setAddParent1] = useState<boolean>(false);
  const [addParent2, setAddParent2] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState<number>(1);
  const [classes, setClasses] = useState<Api.Class[]>([]);

  useEffect(() => {
    getUserInfo();
    if(department!= null){
      RestService.get<Api.Class[]>(`/api/class/GetClassesForDepartment?id=${department?.Id}`)
      .then(response => {
        setClasses([...response]);
      });
    }

  }, [userid, userRoles]);


  const getUserInfo = async () => {
    try {
      var response: Api.User = null;
      if (!usertype) {
        response = await RestService.get<Api.User>(`/api/user/getuser/${userid}`);
        setLookupUser(response);
      }
      else {
        const studresponse = await RestService.get<Api.Student>(`/api/student/getuser/${userid}`);
        const mappedUser: Api.User = {
          DepartmentId: studresponse.DepartmentId,
          DateCreated: studresponse.DateCreated,
          DateModified: studresponse.DateModified,
          Firstname: studresponse.Firstname,
          Lastname: studresponse.Lastname,
          Id: studresponse.Id,
          Roles: studresponse.Roles,
          Email: "",
          Mobilephone: "",
          Department: studresponse.Department,
        }
        setLookupUser(mappedUser);
        response = mappedUser;
        setParents(studresponse.Parents == null ? [] : studresponse.Parents);
        setNote(studresponse.Note);
        setSelectedClass(studresponse.ClassId);

        if(studresponse.DateOfBirth !== null)setDateOfBirth(moment.utc(studresponse?.DateOfBirth).local().format('DD.MM.YYYY'));
      }

      setFirstName(response?.Firstname);
      setLastName(response?.Lastname);
      setMobilePhone(response?.Mobilephone || '');
      setEmail(response?.Email || '');
      setDepartment('todo');
      const roles = response?.Roles.map(role => mapRoleToDisplayName(role.Role));
      setRoles(roles || []);

    } catch (error) {
      console.error(error);
      toast.error(`Feil med lagring. ${error}`);
    }
  };


  const searchPhoneStudent = async (value: string, parent: string) => {
    if (parent == "parent1") setParent1(new PhoneSearch({ message: "" }));
    if (parent == "parent2") setParent2(new PhoneSearch({ message: "" }));

    if (value.length !== 8) return;
    try {
      if (parent == "parent1") setParent1(new PhoneSearch({ showProgress: true, id: Number(value) }));
      if (parent == "parent2") setParent2(new PhoneSearch({ showProgress: true, id: Number(value) }));
      const results: Api.SearchResult[] = await RestService.get<Api.SearchResult[]>(`/api/search?query=${value}&type=studentparents`);
      if (results.length > 0) {
        if (parent == "parent1") {
          setParent1(new PhoneSearch({ showProgress: false, id: Number(value), searchResult: results, message: `Eleven har samme forelder som: ${results[0].Name}?` }))
        }
        if (parent == "parent2") {
          setParent2(new PhoneSearch({ showProgress: false, id:  Number(value), searchResult: results, message: `Eleven har samme forelder som: ${results[0].Name}?`}))
        }
      }
    } catch (error) {
      console.error('Failed to fetch search results:', error);
      toast.error('Failed to fetch search results:', error);
    }
  }

  const saveChanges = async () => {
    try {
      const payload: Api.UpdateUserInfoModel = {
        UserId: lookupUser.Id,
        FirstName: firstName,
        LastName: lastName,
        MobilePhone: mobilePhone,
        Email: email,
      };
      var response: Api.User = null;

      response = await RestService.post<Api.UpdateUserInfoModel, Api.User>('/api/user/UpdateUserInfo', payload);
      toast.success('Lagre info!');
      getUserInfo();
    } catch (error) {
      console.error(error);
      toast.error(`Feil med lagring. ${error}`);
    }
  };

  const saveChangesStudent = async () => {
    try {

      const parent1Id = parent1.id == 0 ? (parents.length == 1 ? parents[0] : 0) : parent1.id;
      const parent2Id = parent2.id == 0 ? (parents.length == 2 ? parents[1] : 0) : parent2.id;
      const payload: Api.UpdateStudentUserInfoModel = {
        FirstName: firstName,
        LastName: lastName,
        UserId: lookupUser.Id,
        DateOfBirth: dateOfBirth,
        ParentIds: [parent1Id, parent2Id],
        ClassId: selectedClass,
        Note: note
      };
      var response: Api.Student = null;

      response = await RestService.post<Api.UpdateStudentUserInfoModel, Api.Student>('/api/student/UpdateUserInfo', payload);
      toast.success('Lagre info!');
      setAddParent1(false);
      setAddParent2(false);
      getUserInfo();
    } catch (error) {
      console.error(error);
      toast.error(`Feil med lagring. ${error}`);
    }
  };

  const deleteParent = async (parentPhoneNumber: number) => {
    const isConfirmed = window.confirm("Er du sikker på at du vil slette telefonnumeret?");

    if (isConfirmed) {
      await RestService.get<Api.Student>(`/api/student/DeleteParentPhone/${userid}/${parentPhoneNumber}`);
      toast.success('Kobling slettet!');
      setAddParent1(false);
      setAddParent2(false);
      getUserInfo();
    } else {
      return;
    }
  }


  
  const handleClassChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedClass(parseInt(event.target.value));
  };

  const getCssForMessagebox = (phoneSearch: PhoneSearch) => {
    let baseClasses = 'ods-padding-vertical-1 ods-padding-horizontal-3';

    if (phoneSearch.id > 0) {
      return `${baseClasses} ods-padding-horizontal-3 ods-color-bg-green ods-color-text-blue-dark`;
    } else {
      return `${baseClasses} ods-padding-horizontal-3 ods-color-bg-red ods-color-text-blue-dark`;
    }
  };

    
  const disableLogicFirstAndLastName = (): boolean => {
    if (usertype && (usertype == RolesHelper.Student || usertype == RolesHelper.Teacher)) {
      if (isAdmiOrLocalAdminOrTeacher) return false;
    }
    else {
      if (!isAdminOrLocalAdmin) return false;
    }
    return true;
  };


  return (
    <>
      <OKomBreadcrumb path='Bruker'></OKomBreadcrumb>
      <div className="ods-margin-top-11-breakpoint-medium ods-grid ods-grid--gap ods-grid--center">
        <div className="ods-grid__column--12 ods-grid__column--6-breakpoint-medium">
          <div className="ods-status-card">
            <div className="ods-status-card__content">
              <h3 className="ods-status-card__heading">Personopplysninger</h3>
              <div className="ods-status-card__actions ods-padding-bottom-4">
                <div className="ods-input">
                  <label className="ods-input__label">
                    Fornavn
                    <input className="ods-input__input" onChange={(e) => setFirstName(e.target.value)} value={firstName} type="text" autoComplete="off" placeholder="Fornavn" disabled={disableLogicFirstAndLastName()}   style={{backgroundColor: disableLogicFirstAndLastName() ? '#dadada' : ''}} />
                  </label>
                </div>

                <div className="ods-input ods-margin-bottom-13">
                  <label className="ods-input__label">
                    Etternavn
                    <input className="ods-input__input" onChange={(e) => setLastName(e.target.value)} value={lastName} type="text" autoComplete="off" placeholder="Etternavn" disabled={disableLogicFirstAndLastName()}   style={{backgroundColor: disableLogicFirstAndLastName() ? '#dadada' : ''}}/>
                  </label>
                </div>


                {usertype && usertype == RolesHelper.Student &&
                  <div>
                    <div className="ods-input">
                      <label className="ods-input__label">
                        Fødselsdato
                        <input onChange={(e) => setDateOfBirth(e.target.value)} className="ods-input__input" value={dateOfBirth} type="text" autoComplete="on" placeholder="Fødselsdato" disabled={!isAdmiOrLocalAdminOrTeacher(userRoles!)} />
                      </label>
                    </div>

                    <div className="ods-input">
                      <label className="ods-input__label">
                        Merknader
                        <input onChange={(e) => setNote(e.target.value)} className="ods-input__input" value={note} type="text" autoComplete="on" placeholder="Merknader" disabled={!isAdmiOrLocalAdminOrTeacher(userRoles!)} />
                      </label>
                    </div>

                    <div className="ods-select">
                      <label className="ods-select__label">
                        Klassetrinn
                        <div className="ods-select__select" >
                          <select value={selectedClass} onChange={handleClassChange}>
                            {classes.map((classob) => (
                              <option key={classob.Id} value={classob.Id}>{classob.Name}</option>
                            ))}
                          </select>
                        </div>
                      </label>
                    </div>
                  </div>
                }

                {!usertype &&
                  <div>
                    <div className="ods-input">
                      <label className="ods-input__label">
                        Mobiltelefon
                        <input onChange={(e) => setMobilePhone(e.target.value)} className="ods-input__input" value={mobilePhone} type="text" autoComplete="on" placeholder="Mobiltelefon" disabled={!isAdmiOrLocalAdminOrTeacher(userRoles!)} />
                      </label>
                    </div>

                    <div className="ods-input ods-input">
                      <label className="ods-input__label">
                        E-post
                        <input onChange={(e) => setEmail(e.target.value)} className="ods-input__input" value={email} type="text" autoComplete="off" placeholder="E-post" disabled={!isAdmiOrLocalAdminOrTeacher(userRoles!)} />
                      </label>
                    </div>
                  </div>
                }

                {usertype && usertype == RolesHelper.Student &&
                  <div>
                    {parents?.map((parent, index) => (
                      <div key={index} className='ods-padding-top-13'>
                        <div className="ods-input">
                          <label className="ods-input__label">
                            Forelder{index + 1} mobiltelefon
                            {parents?.length > 1 && <span onClick={(e) => deleteParent(parent)}> [Slett]</span>
                            }
                            <input
                              className="ods-input__input" defaultValue={parent!} type="text" autoComplete='false' placeholder=""
                              onChange={(e) => searchPhoneStudent(e.target.value, `parent${index + 1}`)}
                            />
                          </label>
                        </div>
                        {index == 0 && parent1.message != "" &&
                          <div className={getCssForMessagebox(parent1)}>
                            <span className=''>{parent1.message}</span>
                          </div>
                        }
                        {index == 1 && parent2.message != "" &&
                          <div className={getCssForMessagebox(parent2)}>
                            <span className=''>{parent2.message}</span>
                          </div>
                        }
                      </div>
                    ))}

                    {!addParent1 && parents?.length == 0 &&
                    <div className="ods-padding-top-7">
                      <a className="o-kom-link" onClick={() => setAddParent1(true)}>+ Legg til forelder1</a>
                      </div>
                    }

                    {addParent1 &&
                      <div className='ods-margin-top-11'>
                        <div className="ods-input">
                          <label className="ods-input__label">
                            Forelder1 mobiltelefon
                            <input
                              className="ods-input__input" type="text" autoComplete='false' placeholder=""
                              onChange={(e) => searchPhoneStudent(e.target.value, `parent1`)}
                            />
                          </label>
                        </div>
                        {parent1.message != "" &&
                          <div className={getCssForMessagebox(parent1)}>
                            <span className=''>{parent1.message}</span>
                          </div>
                        }
                        <a className="o-kom-link" onClick={() => setAddParent1(false)}>- Slett forelder1</a>
                      </div>
                    }


                    {!addParent2 && parents?.length < 2 &&
                      <div className='ods-margin-top-3'>
                        <a className="o-kom-link" onClick={() => setAddParent2(true)}>+ Legg til forelder2</a>
                      </div>
                    }

                    {addParent2 &&
                      <div className='ods-margin-top-11'>
                        <div className="ods-input">
                          <label className="ods-input__label">
                            Forelder2 mobiltelefon
                            <input
                              className="ods-input__input" type="text" autoComplete='false' placeholder=""
                              onChange={(e) => searchPhoneStudent(e.target.value, `parent2`)}
                            />
                          </label>
                        </div>
                        {parent2.message != "" &&
                          <div className={getCssForMessagebox(parent2)}>
                            <span className=''>{parent2.message}</span>
                          </div>
                        }
                        <a className="o-kom-link" onClick={() => setAddParent2(false)}>- Slett forelder2</a>
                      </div>
                    }

                  </div>

                }


              </div>
              {isAdmiOrLocalAdminOrTeacher(userRoles) &&
                <OKomButton
                  buttonText='Lagre'
                  size='medium'
                  onClick={() => usertype && usertype == RolesHelper.Student ? saveChangesStudent() : saveChanges()}
                />}

            </div>
          </div>
        </div>

        <div className="ods-grid__column--12 ods-grid__column--6-breakpoint-medium">
          <div className="ods-status-card">
            <div className="ods-status-card__content">
              <h3 className="ods-status-card__heading">Roller og rettigheter</h3>
              <div className="ods-status-card__actions">


                <div className="ods-input ods-margin-bottom-11">
                  <div className='ods-text--size-india'>Brukerid:</div>
                  {userToken &&
                    <div>{lookupUser?.Id}</div>}
                </div>

                <div className="ods-input ods-margin-bottom-11">
                  <div className='ods-text--size-india'>Roller:</div>
                  {roles?.map((role) => (
                    <div key={role} className="">{role}</div>
                  ))}
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailsView;
