import React, { useState } from 'react';
import OKomButton from './OKomButton';
import RestService from '../../Services/RestService';
import { toast } from 'react-toastify';
import { useUserContext } from '../StateContext/UserContext';


interface OKomWeekPaginationProps {
  defaultUserType?: "teacher" | "student" | "normal";
  onSubmissionComplete?: (id: number, defaultUserType) => void;
}

export class PhoneSearch {
  id: number = 0;
  showProgress: boolean = false;
  message: string = "";
  searchResult: Api.SearchResult[] = [];

  constructor(options?: { message?: string; showProgress?: boolean; id?: number; searchResult?: Api.SearchResult[] }) {
    this.id = options?.id ?? 0;
    this.showProgress = options?.showProgress ?? false;
    this.message = options?.message ?? "";
    this.searchResult = options?.searchResult ?? [];
  }
}

const OKomNewUserComponent: React.FC<OKomWeekPaginationProps> = ({ defaultUserType, onSubmissionComplete }) => {
  const { department } = useUserContext();
  const [_selectedUserType, setSelectedUserType] = useState<string>("teacher");
  const [selectedClass, setSelectedClass] = useState<number>(1);
  const [classes, setClasses] = useState<Api.Class[]>([]);
  const [nonparent, setNonparent] = useState<PhoneSearch>(new PhoneSearch());
  const [parent1, setParent1] = useState<PhoneSearch>(new PhoneSearch());
  const [parent2, setParent2] = useState<PhoneSearch>(new PhoneSearch());
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [note, setNote] = useState<string>("");

  const handleUserTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "student") {
      RestService.get<Api.Class[]>(`/api/class/GetClassesForDepartment?id=${department?.Id}`)
        .then(response => {
          setClasses([...response]);
        })
    }
    setSelectedUserType(event.target.value);
  };


  const searchPhone = async (value: string) => {
    setNonparent(new PhoneSearch({ message: "" }));
    if (value.length !== 8) return;
    try {
      setNonparent(new PhoneSearch({ showProgress: true }));
      const results: Api.SearchResult[] = await RestService.get<Api.SearchResult[]>(`/api/search?query=${value}&type=all`);
      setNonparent(new PhoneSearch({ showProgress: false, message: results.length > 0 ? `Rett opp feil: Det finnes allerede en bruker med dette nummeret: ${results[0].Name}` : "" }));
      if (results.length === 0) setMobilePhone(value);
    } catch (error) {
      setNonparent(new PhoneSearch({ message: JSON.stringify(error), showProgress: false }));
      console.error('Failed to fetch search results:', error);
      toast.error('Failed to fetch search results:', error);
    }
  };


  const searchPhoneStudent = async (value: string, parent: string) => {
    if (parent === "parent1") setParent1(new PhoneSearch({ message: "" }));
    if (parent === "parent2") setParent2(new PhoneSearch({ message: "" }));

    if (value.length !== 8) return;
    try {
      if (parent === "parent1") setParent1(new PhoneSearch({ showProgress: true, id: Number(value) }));
      if (parent === "parent2") setParent2(new PhoneSearch({ showProgress: true, id: Number(value) }));
      const results: Api.SearchResult[] = await RestService.get<Api.SearchResult[]>(`/api/search?query=${value}&type=studentparents`);
      if (results.length > 0) {
        if (parent === "parent1") {
          setParent1(new PhoneSearch({ showProgress: false, id: Number(value), searchResult: results, message: `Eleven har samme forelder som: ${results[0].Name}?` }))
        }
        if (parent === "parent2") {
          setParent2(new PhoneSearch({ showProgress: false, id: Number(value), searchResult: results, message: `Eleven har samme forelder som: ${results[0].Name}?` }))
        }
      }
    } catch (error) {
      console.error('Failed to fetch search results:', error);
      toast.error('Failed to fetch search results:', error);
    }
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleDateOfBirthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfBirth(e.target.value);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const getCssForMessagebox = (phoneSearch: PhoneSearch) => {
    let baseClasses = 'ods-padding-vertical-1 ods-padding-horizontal-3';

    if (phoneSearch.id > 0) {
      return `${baseClasses} ods-padding-horizontal-3 ods-color-bg-green ods-color-text-blue-dark`;
    } else {
      return `${baseClasses} ods-padding-horizontal-3 ods-color-bg-red ods-color-text-blue-dark`;
    }
  };


  const handleClickSubmit = async () => {
    if (mobilePhone === "" || firstName === "" || lastName === "") return;
    try {
      const payload: Api.CreateUserModel = {
        FirstName: firstName,
        LastName: lastName,
        MobilePhone: mobilePhone,
        UserType: _selectedUserType
      };

      await RestService.post<Api.CreateUserModel, Api.User>('/api/user/createuser', payload);
      toast.success('Bruker opprettet!');
    } catch (error) {
      console.error('Failed:', error);
      toast.error('Failed', error);
    }
  };


  const handleClickSubmitStudent = async () => {
    if (firstName === "" || lastName === "") return;

    try {
      const payload: Api.CreateStudentModel = {
        FirstName: firstName,
        LastName: lastName,
        DateOfBirth: dateOfBirth,
        Mobilephone: mobilePhone,
        Parent1Id: parent1.id,
        Parent2Id: parent2.id,
        ClassId: selectedClass,
        Note: note
      };

      await RestService.post<Api.CreateStudentModel, Api.Student>('/api/student/createuser', payload);
      toast.success('Bruker opprettet!');
    } catch (error) {
      console.error('Failed:', error);
      toast.error('Failed', error);
    }
  };



  const handleClassChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedClass(parseInt(event.target.value));
  };


  return (
    <div className="ods-grid__column--12">

      <div className="ods-select ods-margin-bottom-11">
        <label className="ods-select__label">
          Type bruker
          <div className="ods-select__select" >
            <select defaultValue={_selectedUserType} onChange={handleUserTypeChange}>
              <option value="teacher">Lærer</option>
              <option value="student">Elev</option>
              <option value="normal">Vanlig bruker</option>
            </select>
          </div>
        </label>
      </div>

      <div className="ods-search-inline">
        <label className="ods-search-inline__label">Mobiltelefon  {_selectedUserType === "student" && <span>(valgfritt)</span>}
          <input
            placeholder={"Mobiltelefon"}
            className="ods-search-inline__input"
            type="search"
            autoComplete="off"
            onChange={(e) => searchPhone(e.target.value)}
          />
        </label>
      </div>


      <div>
        {nonparent.message !== "" &&
          <div className='ods-padding-vertical-1 ods-padding-horizontal-3 ods-color-bg-red ods-color-text-blue-dark'>
            <span className=''>{nonparent.message}</span>
          </div>
        }
      </div>
      

      <div className="ods-input">
        <label className="ods-input__label">
          Fornavn
          <input onChange={handleFirstNameChange} value={firstName} className="ods-input__input" type="text" autoComplete='false' placeholder="" />
        </label>
      </div>

      <div className="ods-input">
        <label className="ods-input__label">
          Etternavn
          <input onChange={handleLastNameChange} value={lastName} className="ods-input__input" type="text" autoComplete='false' placeholder="" />
        </label>
      </div>

      {_selectedUserType === "student" &&
        <>
          <div className="ods-input">
            <label className="ods-input__label">
              Fødselsdato (valgfritt)
              <input onChange={handleDateOfBirthChange} value={dateOfBirth} className="ods-input__input" type="text" autoComplete='false' placeholder="format: 25.01.2010" />
            </label>
          </div>

          <div className="ods-input">
            <label className="ods-input__label">
              Merknader (valgfritt)
              <input onChange={handleNoteChange} value={note} className="ods-input__input" type="text" autoComplete='false' placeholder="Merknader" />
            </label>
          </div>
        </>
      }


      {_selectedUserType === "student" &&
        <div className='ods-margin-top-11'>
          <div className="ods-select">
            <label className="ods-select__label">
              Klassetrinn
              <div className="ods-select__select" >
                <select defaultValue={classes?.[0]?.Id} onChange={handleClassChange}>
                  {classes.map((classob) => (
                    <option key={classob.Id} value={classob.Id}>{classob.Name}</option>
                  ))}
                </select>
              </div>
            </label>
          </div>

          <div className="ods-search-inline">
            <label className="ods-search-inline__label">Forelder1 mobiltelefon
              <input
                placeholder={"Mobiltlf til eksisterende forelder"}
                className="ods-search-inline__input"
                type="search"
                autoComplete="off"
                onChange={(e) => searchPhoneStudent(e.target.value, "parent1")}
              />
            </label>
          </div>
          {parent1.message !== "" &&
            <div className={getCssForMessagebox(parent1)}>
              <span className=''>{parent1.message}</span>
            </div>
          }


          <div className="ods-search-inline">
            <label className="ods-search-inline__label">Forelder2 mobiltelefon (valgfritt)
              <input
                placeholder={"Mobiltlf til eksisterende forelder"}
                className="ods-search-inline__input"
                type="search"
                autoComplete="off"
                onChange={(e) => searchPhoneStudent(e.target.value, "parent2")}
              />
            </label>
          </div>
          {parent2.message !== "" &&
            <div className={getCssForMessagebox(parent2)}>
              <span className=''>{parent2.message}</span>
            </div>
          }
        </div>
      }

      <div className='ods-margin-top-11'>
        <OKomButton
          buttonText='Opprett'
          size='medium'
          onClick={() => _selectedUserType === "student" ? handleClickSubmitStudent() : handleClickSubmit()}
        />
      </div>

    </div>
  );
};

export default OKomNewUserComponent;


