import myconfig from '../Config';

class RestService<T> {
    static isLoading = false; // Added isLoading property


    private static getAuthHeader(): HeadersInit {
        const token = localStorage.getItem('myToken');
        return {
            'Content-Type': 'application/json',
            ...(token ? { 'Authorization': `Bearer ${token}` } : {})
        };
    }

    static async get<T>(url: string): Promise<T> {
        try {
            this.isLoading = true; // Start loading
            const response = await fetch(myconfig.rootUrl + url, {
                method: 'GET',
                headers: this.getAuthHeader(),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json() as T;
            this.isLoading = false; // Stop loading
            return data;
        } catch (error) {
            this.isLoading = false; // Stop loading on error
            throw new Error(`Failed to get data: ${error}`);
        }
    }

    static async post<T, R>(url: string, data: T): Promise<R> {
        try {
            this.isLoading = true; // Start loading
            const response = await fetch(myconfig.rootUrl + url, {
                method: 'POST',
                headers: this.getAuthHeader(),
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const result = await response.json() as R;
            this.isLoading = false; // Stop loading
            return result;
        } catch (error) {
            this.isLoading = false; // Stop loading on error
            throw new Error(`Failed to post data: ${error}`);
        }
    }

}

export default RestService;
