class DateHelperUtil {
    static getWeekNumber(date?: Date): number {
        if (!date) {
            date = new Date();
        }
        const tempDate = new Date(date.valueOf());
        const dayNum = (date.getDay() + 6) % 7;
        tempDate.setDate(tempDate.getDate() - dayNum + 3);
        const firstThursday = tempDate.valueOf();
        tempDate.setMonth(0, 1);
        if (tempDate.getDay() !== 4) {
            tempDate.setMonth(0, 1 + ((4 - tempDate.getDay()) + 7) % 7);
        }

        return 1 + Math.ceil((firstThursday - tempDate.valueOf()) / 604800000);
    }
}

export default DateHelperUtil;
