import React, { useEffect, useState } from 'react';
import myconfig from '../Config';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext, useUserContext } from '../Components/StateContext/UserContext';
import { RouteComponentProps, withRouter, useParams, useHistory } from 'react-router-dom';
import OKomNewUserComponent from '../Components/OsloKommuneUI/OKomNewUserComponent';
import OKomTypeahead from '../Components/OsloKommuneUI/OKomTypeahead';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb';
import OKomSearchResultCard from '../Components/OsloKommuneUI/OKomSearchResultCard';
import RestService from '../Services/RestService';
import { mapRoleToDisplayName } from '../Utils/RoleMappingUtil';
import { CachedSearch, RolesHelper } from '../Models/Interfaces';


const UserSearchView: React.FC<RouteComponentProps> = (props) => {
  const { depId, classId, week } = useParams<{ depId: string; classId: string; week: string }>();
  const [searchResults, setSearchResults] = useState<Api.SearchResult[]>([]);
  const history = useHistory();
  useEffect(() => {
    const cachedSearch = sessionStorage.getItem("cachedSearch");
    if (cachedSearch) {
      const parsedCachedSearch: CachedSearch = JSON.parse(cachedSearch);
      setSearchResults(parsedCachedSearch.Result);
    }
  }, [depId, classId, week]);


  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const search = async (value: string) => {
    if (value.length < 4) return;
    try {
      const results: Api.SearchResult[] = await RestService.get<Api.SearchResult[]>(`/api/search?query=${value}&type=all`);
      setSearchResults(results);
      const cachedResult: CachedSearch = {
        Query: value,
        Result: results
      }
      sessionStorage.setItem("cachedSearch", JSON.stringify(cachedResult));
    } catch (error) {
      console.error('Failed to fetch search results:', error);
      setSearchResults([]);
    }
  };

  const debouncedSearch = debounce(search, 500);

  function showMoreClicked(id: number, isStudent: boolean): void {
    history.push(isStudent ? `/userdetails/${id}/student` : `/userdetails/${id}`);
  }

  const generateRandomId = (): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
 

  const showAllUsersOfType = async (userType: string) => {
    try {
      const results: Api.SearchResult[] = await RestService.get<Api.SearchResult[]>(`/api/search/searchUserType?userType=${userType}`);
      setSearchResults(results);
      const cachedResult: CachedSearch = {
        Query: userType,
        Result: results
      }
      sessionStorage.setItem("cachedSearch", JSON.stringify(cachedResult));
    } catch (error) {
      console.error('Failed to fetch search results:', error);
      setSearchResults([]);
    }
  }

  return (
    <>
      <OKomBreadcrumb path='Brukeradmin'></OKomBreadcrumb>
      <div className="ods-margin-top-11-breakpoint-medium ods-grid ods-grid--center">
        <div className="ods-grid__column--start-2 ods-grid__column--end-12 ods-grid__column--start-4-breakpoint-medium ods-grid__column--end-10-breakpoint-medium">
          <h1 className='ods-text--align-center ods-text--size-kilo ods-text--size-echo-breakpoint-medium'>Finn elev, lærer eller forelder</h1>
          <div className="ods-search-inline">
            <label className="ods-search-inline__label">
              <input
                placeholder='søk på navn, telefonnummer eller epost'
                className="ods-search-inline__input"
                type="search"
                autoComplete="off"
                aria-expanded="true"
                aria-haspopup="listbox"
                aria-owns="id-results-2"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="ods-margin-top-3-breakpoint-medium ods-grid ods-grid--center">
        <div className="ods-grid__column--start-2 ods-grid__column--end-12 ods-grid__column--start-4-breakpoint-medium ods-grid__column--end-10-breakpoint-medium">
          <div className="ods-grid ods-grid--gap">

            <div className="ods-grid__column--4">
              <div><button onClick={() => { showAllUsersOfType("teacher") }} className="ods-button ods-button--text">Vis lærere</button></div>
            </div>
            <div className="ods-grid__column--4">
              <div><button onClick={() => { showAllUsersOfType("student") }} className="ods-button ods-button--text">Vis elever</button></div>
            </div>

            <div className="ods-grid__column--4">
              <div><button onClick={() => { showAllUsersOfType("all") }} className="ods-button ods-button--text">Vis alle</button></div>
            </div>
          </div>
        </div>
      </div>

      <div className="ods-margin-top-25">
        {chunkArray(searchResults, 6).map((chunk, index) => (
          <div key={index} className='ods-grid'>
            {chunk.map((result: Api.SearchResult) => (
              <div key={`${index} + ${generateRandomId()}`} className='ods-grid__column--2 ods-padding-3'>
                <OKomSearchResultCard
                  onShowMoreClicked={() => showMoreClicked(result.Id, result.Roles.filter(d => d.Role == RolesHelper.Student).length > 0)}
                  key={result.Id}
                  header={result.Name}
                  footer={result.Roles.map(role => mapRoleToDisplayName(role.Role)).join(', ')}>
                  <strong>Mobiltelefon:</strong> {result.Mobilephone}
                </OKomSearchResultCard>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default withRouter(UserSearchView);
