// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import Frontpage from './Views/FrontpageView';
import AttendanceView from './Views/AttendanceView';
import ClassOverView from './Views/ClassOverView';
import CreateUserView from './Views/CreateUserView';
import LoginView from './Views/LoginView';
import myconfig from './Config';
import { useUserContext } from './Components/StateContext/UserContext'; // Ensure the path is correct
import UserSearchView from './Views/UserSearchView';
import CalenderView from './Views/CalenderView';
import MyUserDetailsView from './Views/MyUserDetailsView';
import UserDetailsView from './Views/UserDetailsView';
import PaymentView from './Views/PaymentView';

const App = () => {
    const { user, department, setToken, setDepartmentInfo, setUserInfo } = useUserContext();

    const logoutCLicked = () => {
        setDepartmentInfo(null);
        setUserInfo(null);
        setToken(null);
        window.location.href = '/login';
    };

    return (
        <>
            <div className="ods-grid__column--12">
                <div className="ods-color-bg-yellow ods-padding-3 ods-text--size-echo">
                    <div className="ods-grid ods-grid--gap ods-grid--dense">
                        <div className="ods-grid__column--12 ods-grid__column--start-10-breakpoint-medium ods-grid__column--end-13-breakpoint-medium">
                            <div className="ods-text--align-right ods-text--size-kilo">{user?.Firstname} {user?.Lastname}
                                {user?.Id != null 
                                    &&
                                    <a className='o-kom-link' onClick={logoutCLicked}> [Logg ut]</a>}
                            </div>
                        </div>
                        <div className="ods-grid__column--12 ods-grid__column--end-9-breakpoint-medium ods-text--size-kilo ods-text--size-echo-breakpoint-medium ods-none ods-block-breakpoint-medium">
                            <div className="ods-text--align-left">
                                <a title={myconfig?.appVersion} href='/'>Rommen leksehjelp web 1.0</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Router>
                <Route exact path="/" component={Frontpage} />
                <Route exact path="/attendance/:classId/:week" component={AttendanceView} />
                <Route exact path="/payments/:depId" component={PaymentView} />
                <Route exact path="/classes/:depId" component={ClassOverView} />
                <Route exact path="/usersearch/:depId" component={UserSearchView} />
                <Route exact path="/createuser/:depId" component={CreateUserView} />
                <Route exact path="/roombooking/:depId" component={CalenderView} />
                <Route exact path="/login" component={LoginView} />
                <Route exact path="/userdetails/:userid/:usertype?" component={UserDetailsView} />
                <Route exact path="/myuserdetails" component={MyUserDetailsView} />
            </Router>
        </>
    );
};

export default App;
