import React, { useEffect, useRef, useState } from 'react';
import myconfig from '../Config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext, useUserContext } from '../Components/StateContext/UserContext';
import { RouteComponentProps, withRouter, useParams, useHistory } from 'react-router-dom';
import RestService from '../Services/RestService';
import OKomLoading from '../Components/OsloKommuneUI/OKomLoading';
import { jwtDecode } from 'jwt-decode';
import PinField from "react-pin-field";
import cn from "classnames";

const LoginView: React.FC<RouteComponentProps> = (props) => {
  const { depId, classId, week } = useParams<{ depId: string; classId: string; week: string }>();
  const { setUserInfo, setDepartmentInfo, setToken } = useUserContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>("");
  const [showPinCodeForm, setShowPinCodeForm] = useState<boolean>(false);
  const [pinCompleted, setPinCompleted] = useState(false);

  useEffect(() => {

  }, [depId, classId, week]);


  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await RestService.get<Api.LoginResponse>('/api/login/testlogin');
      if (response.Success) {
        setToken(response.Token);
        const response_myinfo = await RestService.get<Api.User>(`/api/user/getuser/${jwtDecode(response.Token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]}`);
        setUserInfo(response_myinfo);
        const response_department = await RestService.get<Api.Department>(`/api/department/GetDepartment?id=${response_myinfo.DepartmentId}`);
        setDepartmentInfo(response_department);
        history.push('/');
      } else {
        toast.error(`Noe gikk galt: ${response.Message}`);
      }
    } catch (error) {
      toast.error(`Noe gikk galt: ${error}`);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleLoginUser = async () => {
    try {
      setIsLoading(true);
      const response = await RestService.get<Api.LoginResponse>(`/api/login/sendpin?phoneNumber=${userPhoneNumber}`);
      if (response.Success) {
        setShowPinCodeForm(true);
        if(response.Message != null){
          toast.success(response.Message);
        }
      } else {
        toast.error(`Noe gikk galt: ${response.Message}`);
      }
    } catch (error) {
      toast.error(`Noe gikk galt: ${error}`);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const handlePinCode = async (pin: string) => {
    try {
      setIsLoading(true);
      const response = await RestService.get<Api.LoginResponse>(`/api/login/login?phoneNumber=${userPhoneNumber}&pinCode=${pin}`);
      if (response.Success) {
        setToken(response.Token);
        const response_myinfo = await RestService.get<Api.User>(`/api/user/getuser/${jwtDecode(response.Token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]}`);
        setUserInfo(response_myinfo);
        const response_department = await RestService.get<Api.Department>(`/api/department/GetDepartment?id=${response_myinfo.DepartmentId}`);
        setDepartmentInfo(response_department);
        history.push('/');
      } else {
        setPinCompleted(false);
        toast.error(`${response.Message}`);
      }
    } catch (error) {
      toast.error(`Noe gikk galt: ${error}`);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserPhoneNumber(event.target.value);

  };

  
  const onPinChange = (pin: string) => {
    if (pin.length == 5) {
      handlePinCode(pin);
    }
  };


  return (
    <>
      {isLoading === true && <OKomLoading text='Logger inn, vennligst vent...'></OKomLoading>}
      <div className="ods-margin-top-25 ods-grid ods-grid--gap ods-grid--center">

        <div className="ods-grid__column--12 ods-grid__column--start-3-breakpoint-medium ods-grid__column--end-10-breakpoint-medium">
          <div className="ods-status-card">
            <div className="ods-status-card__content">

              {showPinCodeForm &&
                <>
                   <span>Vi har sendt deg en pin kode på SMS, det kan ta opptil 2 minutter. Hvis det ikke har kommet fram, prøv på nytt</span>
                  <div className="pin-field-container">

                  <PinField
                    validate="0123456789"
                    inputMode="numeric"
                    className={cn("pin-field", { complete: pinCompleted })}
                    onComplete={() => setPinCompleted(true)}
                    format={k => k.toUpperCase()}
                    autoFocus
                    onChange={(e) => onPinChange(e)}
                    disabled={pinCompleted}
                    autoComplete="one-time-password"
                  />
                  </div>
                <div className="ods-status-card__actions">
                  <div className="ods-text--align-center">
                    <button disabled={isLoading} className="ods-button ods-button--outline" onClick={(e) => setShowPinCodeForm(false)}><span className="ods-button__icon ods-button__icon--right ods-icon--chevron-thin-left"></span> Logg inn på nytt</button></div>
                </div>
              </>
              }
              {!showPinCodeForm &&
                <>
                  <h3 className="ods-status-card__heading">INNLOGGING</h3>
                  <label className="ods-input__label ods-margin-top-11">
                    Mobilnummer
                  <input value={userPhoneNumber}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleLoginUser();
                      }
                    }}
                    onChange={(e) => onPhoneNumberChange(e)}
                    className="ods-input__input"
                    type="text"
                    autoComplete="off"
                    placeholder="uten +47" />
                  </label>
                  <div className="ods-status-card__actions">
                    <div className="ods-text--align-center">
                      <button disabled={isLoading} className="ods-button ods-button" onClick={handleLoginUser}>Logg inn</button></div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>



      </div>
    </>
  );
}

export default withRouter(LoginView);
