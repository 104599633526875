import React, { useState, forwardRef, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import nb from 'date-fns/locale/nb';


interface DatePickerProps {
    defaultStartDate?: Date;
    value?: string;
    label?: string;
    onClick?: () => void;
    onDateChanged?: (date: Date) => void;
}

const OKomDatePicker: React.FC<DatePickerProps> = ({ value, label, onClick, onDateChanged, defaultStartDate }) => {
    const [startDate, setStartDate] = useState<Date | null>(new Date());


    useEffect(() => {
        if(defaultStartDate!==null) setStartDate(defaultStartDate);
      }, [defaultStartDate])

    function onChange(date: any) {
    }

    const CustomInput = forwardRef<HTMLInputElement, DatePickerProps>(
        ({ value, onClick }, ref) => (
            <div className="ods-input">
                <label className="ods-input__label">
                    {label}
                    <input value={value} onChange={onChange} className="ods-input__input" type="text" onClick={onClick} ref={ref} />
                </label>
            </div>
        )

    );

    function dateChanged(date: any) {
        setStartDate(date);
        onDateChanged(date);
    }

    return (
        <>
            <DatePicker
                selected={startDate}
                onChange={(date) => dateChanged(date)}
                locale={nb}
                showTimeSelect
                timeFormat="p"
                timeIntervals={30}
                dateFormat="Pp"
                customInput={<CustomInput />}
            />

        </>
    );
};

export default OKomDatePicker;