import React from 'react';

interface OKomButtonProps {
    buttonText: string; 
    onClick?: () => void;
    disabled?: boolean;
    size?: 'large' | 'medium' | 'small';
    color?: 'yellow' | 'blue-dark' | 'green-dark' | 'green' | 'blue' | 'white' | 'red' | 'black' | 'gray';
}

const OKomButton: React.FC<OKomButtonProps> = ({ children, buttonText, onClick, disabled = false, size, color }) => {
    const buttonClass = `ods-button ${
        size === 'large' ? 'ods-button--large' :
        size === 'medium' ? 'ods-button--medium' :
        size === 'small' ? 'ods-button--small' : ''
    } ${color ? `ods-button--${color}` : ''}`;
    return (
        <button className={buttonClass} onClick={onClick} disabled={disabled}>
           {children} {buttonText} 
        </button>
    );
};

export default OKomButton;