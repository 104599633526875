import { Calendar, Messages, View, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, useParams, withRouter } from 'react-router-dom'
import { useUserContext } from '../Components/StateContext/UserContext'
import 'moment/locale/nb';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb'
import OKomDatePicker from '../Components/OsloKommuneUI/OKomDatePicker'
import { toast } from 'react-toastify'
import RestService from '../Services/RestService'
import OKomLoading from '../Components/OsloKommuneUI/OKomLoading';
import { userInfo } from 'os';

moment.locale('nb');
const localizer = momentLocalizer(moment)

interface CustomEvent {
  id: number;
  title: string;
  allDay?: boolean;
  start: Date;
  end: Date;
  bookingType: string;
}


const CalenderView: React.FC<RouteComponentProps> = (props) => {
  const { user, userRoles } = useUserContext();
  const [selectedBooking, setSelectedBooking] = useState<Api.CustomEvent | null>(null);
  const [showBookingForm, setShowBookingForm] = useState<boolean>(false);
  const [showBookingComplete, setShowBookingComplete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [title, setTitle] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [curentMonthRange, setCurentMonthRange] = useState<number[]>([new Date().getMonth(), new Date().getMonth() + 1, new Date().getMonth() + 2]);
  const messages: Messages = {
    agenda: "Oversikt",
    allDay: "Heldag",
    event: "Arrangement",
    date: "Dato",
    day: "Dag",
    month: "Måned",
    next: "Neste",
    noEventsInRange: "Ingen arrangement",
    previous: "Forrige",
    time: "Tid",
    today: "Idag",
    week: "Uke",
    tomorrow: "Imorn",
    yesterday: "Igår",
    work_week: "Arbeidsdager"
  }
  const [myEventsList, setMyEventsList] = useState<CustomEvent[]>([]);
  const [serverEventList, setServerEventList] = useState<Api.CustomEvent[]>([]);


  const clickRef = useRef(null)

  useEffect(() => {

    setIsLoading(true);
    RestService.get<Api.CustomEvent[]>(`/api/roombooking/GetBookingsForMonth?month=${curentMonthRange[1]}`)
      .then(response => {
        setServerEventList([...response]);
        const mapperResponse: CustomEvent[] = response.map((event: Api.CustomEvent): CustomEvent => ({
          id: event.Id,
          end: new Date(event.End),
          start: new Date(event.Start),
          title: event.Title,
          allDay: event.AllDay,
          bookingType: event.BookingType
        }));
        setIsLoading(false);
        setMyEventsList([...mapperResponse]);
      });

    return () => {
      window.clearTimeout(clickRef?.current)
    }
  }, [curentMonthRange])

  const handleSelectEvent = useCallback(
    (event: CustomEvent) => {
      setSelectedBooking(serverEventList.find(x=> x.Id == event.id));
      setShowBookingForm(false);
      setShowBookingComplete(false);
    },
    [serverEventList]
  );

  const onSelectSlot = useCallback((slotInfo: any) => {
    window.clearTimeout(clickRef?.current)
    clickRef.current = window.setTimeout(() => {
      if(slotInfo.slots.length < 3) return;
      setSelectedBooking(null);
      if(!showBookingForm){
        setShowBookingForm(true);
        setShowBookingComplete(false);
      }
      setFromDate(slotInfo.start);
      setToDate(slotInfo.end);
    }, 250)
  }, [])



  function dateChangedFrom(date: Date) {
    setFromDate(date);

  }

  function dateChangedTo(date: Date) {
    setToDate(date);
  }

  const handleClickSubmit = async () => {
    if (fromDate === null || toDate === null || title === "") {
      toast.error("fradato,tildato og tittel må være fyllt");
      return;
    }
    try {
      const payload: Api.CreateBookingModel = {
        FromDateTime: fromDate.toUTCString(),
        ToDateTime: toDate.toUTCString(),
        Comment: comment,
        EventName: title,
        RoomId: 1,
        BookingType: ""
      };

      var response = await RestService.post<Api.CreateBookingModel, Api.LoginResponse>('/api/roombooking/CreateBooking', payload);
      if (!response.Success) toast.error(response.Message);
      else {
        setShowBookingComplete(true);
        setShowBookingForm(false)
      }
    } catch (error) {
      console.error('Failed:', error);
      toast.error('Failed', error);
    }
  };


  const handleClickShowBookingForm = async () => {
    setShowBookingForm(true);
    setShowBookingComplete(false);
    setSelectedBooking(null);
  };


  function changeRange(range: Date[] | { start: Date; end: Date; }, view?: View): void {
    if(Array.isArray(range) && range.every(item => item instanceof Date)) {
      const months = range.map(date => date.getMonth() + 1);
      const uniqueMonths = Array.from(new Set(months));
      uniqueMonths.forEach(month => {
        if(!curentMonthRange.includes(month))
        {
          console.log("timerange exceed, fetching")
          setCurentMonthRange([month-1, month, month+1])
          return;
        }
      });
    }
    else if(range && 'start' in range && 'end' in range && range.start instanceof Date && range.end instanceof Date) {
      const startMonth = range.start.getMonth() + 1; 
      const endMonth = range.end.getMonth() + 1;
      if(!curentMonthRange.includes(startMonth)) setCurentMonthRange([startMonth-1, startMonth, startMonth+1]);
      if(!curentMonthRange.includes(endMonth)) setCurentMonthRange([endMonth-1, endMonth, endMonth+1]);

    }
  }

  const handleCancelBooking = async () => {
    var response: Api.LoginResponse = await RestService.get<Api.LoginResponse>(`/api/roombooking/DeleteBooking?bookingId=${selectedBooking.Id}`);
    if (!response.Success) toast.error(response.Message);
    else {
      toast.success(response.Message);
      setSelectedBooking(null);
    }
  }

  return (
    <>
      <OKomBreadcrumb path='Klasseoversikt'></OKomBreadcrumb>
      <div className="ods-grid">
        <div className="ods-grid__column--12">

          <div className="ods-grid ods-grid--center">
            {!showBookingForm && (
              <div className="ods-grid__column--start-3 ods-grid__column--end-11 ods-padding-bottom-19">
                <button className="ods-button ods-button--fullwidth" onClick={handleClickShowBookingForm}>
                  Book rom
                </button>
              </div>
            )}
            {showBookingForm &&
              <div className="ods-grid__column--12 ods-grid__column--start-3-breakpoint-medium ods-grid__column--end-11-breakpoint-medium ods-padding-bottom-19">
                <div className="ods-status-card">
                  <div className="ods-status-card__content">
                    <h3 className="ods-status-card__heading">Book room</h3>
                    <div className="ods-status-card__actions">
                      <div className="ods-grid">

                        <div className="ods-grid__column--12">
                          <OKomDatePicker defaultStartDate={fromDate} onDateChanged={dateChangedFrom} label='Fra'></OKomDatePicker>
                        </div>


                        <div className="ods-grid__column--12 ods-padding-top-3">
                          <OKomDatePicker defaultStartDate={toDate} onDateChanged={dateChangedTo} label='Til '></OKomDatePicker>
                        </div>

                        <div className="ods-grid__column--12 ods-padding-top-3">
                          <label className="ods-input__label">
                            Tittel
                            <input onChange={(e) => setTitle(e.target.value)} value={title} className="ods-input__input" type="text" autoComplete="off" placeholder="En kort beskrivelse av arrangementet. Maks 150 tegn" />
                          </label>
                        </div>

                        <div className="ods-grid__column--12 ods-padding-top-3">
                          <div className="ods-textarea">
                            <label className="ods-textarea__label">
                              Kommentar til TRVS (valgfritt)
                              <textarea onChange={(e) => setComment(e.target.value)} value={comment} className="ods-textarea__textarea" rows={4} placeholder="Trenger prosjektor, ekstra stoler, lydanlegg? Alt som skrives her vil kun være synlig for TRVS' utleieansvarlig"></textarea>
                            </label>
                          </div>
                          <button onClick={handleClickSubmit} className="ods-button ods-button--yellow ods-button--fullwidth ods-margin-top-3">Send bestilling</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {showBookingComplete &&
              <div className="ods-grid__column--12 ods-grid__column--start-3-breakpoint-medium ods-grid__column--end-11-breakpoint-medium ods-padding-bottom-19">
                <div className="ods-status-card">
                  <div className="ods-status-card__content">
                    <h3 className="ods-status-card__heading">Takk for påmelding!</h3>
                    <div className="ods-status-card__actions">
                      <div className="ods-grid">
                        <div className="ods-grid__column--12">
                          <h3>
                            Utleieansvarlig har fått varsel og vil godkjenne denne så fort som mulig! Du vil motta en SMS med bekreftelse på {user.Mobilephone}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {selectedBooking != null &&
              <div className="ods-grid__column--12 ods-grid__column--start-3-breakpoint-medium ods-grid__column--end-11-breakpoint-medium ods-padding-top-3 ods-padding-bottom-19">
                <div className="ods-status-card">
                  <div className="ods-status-card__content">
                    <h3 className="ods-status-card__heading">{selectedBooking.Title}</h3>
                    <div className="ods-status-card__actions">
                      <div className="ods-grid">
                        <div className="ods-grid__column--12">
                          <h3>
                            Start: {moment(selectedBooking.Start).format('DD.MM.YYYY HH:mm')}
                          </h3>
                          <h3>
                            Slutt: {moment(selectedBooking.End).format('DD.MM.YYYY HH:mm')}
                          </h3>
                          <h3>
                            Bruker: {selectedBooking.BookedByUser}
                          </h3>
                          {(userRoles.includes("localadmin")  || userRoles.includes("admin") || selectedBooking.BookedByUserId == user.Id) &&
                          <button onClick={handleCancelBooking} className="ods-button ods-button--red ods-button--fullwidth ods-margin-top-3">Avlys</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="ods-grid__column--12">
            {isLoading === true && <OKomLoading></OKomLoading>}
              <Calendar
              className='ods-margin-top-3'
                localizer={localizer}
                events={myEventsList}
                defaultDate={new Date()}
                views={{
                  month: true,
                  week: true,
                  day: false,
                  agenda: true,
                }}
                defaultView={Views.WEEK}
                messages={messages}
                startAccessor="start"
                endAccessor="end"
                min={new Date(1972, 0, 0, 8, 0, 0, 0)}
                max={new Date(1972, 0, 0, 23, 0, 0, 0)}
                style={{ height: 750 }}
                step={30}
                timeslots={2}
                onRangeChange={changeRange}
                onSelectSlot={onSelectSlot}
                onSelectEvent={handleSelectEvent}
                selectable
              />
            </div>

    
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CalenderView);
