import React, { useState } from 'react';

interface OKomTextFieldProps {
    text : string;
    placeholderText? : string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; 
    onBlur?: () => void; 
}

const OKomTextField: React.FC<OKomTextFieldProps> = ({ onBlur, onChange, text, placeholderText }) => {
    const [inputValue, setInputValue] = useState(text);
   
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <div>
            <div className="ods-input">
                <input className="ods-input__input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={inputValue}
                    type="text"
                    autoComplete="off"
                    placeholder={placeholderText} />
            </div>
        </div>
    );
};

export default OKomTextField;

