import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RouteComponentProps, withRouter, useParams, useHistory } from 'react-router-dom';
import OKomButton from '../Components/OsloKommuneUI/OKomButton';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb';
import RestService from '../Services/RestService';
import moment from 'moment';
import 'moment/locale/nb';
import DateHelperUtil from '../Utils/DateHelperUtil';
import OKomLoading from '../Components/OsloKommuneUI/OKomLoading';
moment.locale('nb');

const ClassOverView: React.FC<RouteComponentProps> = (props) => {
  const { depId } = useParams<{ depId: string; }>();
  const [extendedClasses, setExtendedClasses] = useState<Api.ClassExtended[]>([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    RestService.get<Api.ClassExtended[]>(`/api/class/GetClassesForDepartment?id=${depId}`)
      .then(response => {
        setExtendedClasses(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [depId]);


  const btnSaveClicked = (classId: number) => {
    history.push(`/attendance/${classId}/${DateHelperUtil.getWeekNumber()}`);
  };

  return (
    <>
      <OKomBreadcrumb path='Klasseoversikt'></OKomBreadcrumb>
      {isLoading === true && <OKomLoading></OKomLoading>}
      <div className="ods-grid ods-margin-top-11-breakpoint-medium">
        <div className="ods-grid__column--12">
          <div className="ods-grid ods-grid--center">
                <div className="ods-grid__column--12">
                  <table className="ods-table ods-table--full-width">
                    <tbody className="ods-table__body">
                      {extendedClasses.map((classItem) => (
                        <tr key={classItem.Id} className="ods-table__row">
                          <th scope="col" className="ods-table__heading">
                            <h1>{classItem.Name}</h1>
                          </th>
                          <td>
                            <OKomButton
                              buttonText='Fraværregistrering'
                              size='small'
                              onClick={() => btnSaveClicked(classItem.Id)}
                            />
                          </td>
                          <td className="ods-table__cell" title={classItem.DateModified ? moment.utc(classItem.DateModified).local().format('DD.MM.YYYY HH:mm') : ''}>
                            {classItem.DateModified ? moment.utc(classItem.DateModified).fromNow() : 'Ikke oppdatert'}
                          </td>
                          <td className="ods-table__cell ods-none ods-block-breakpoint-medium">
                            {classItem.ModifiedBy}
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default withRouter(ClassOverView);
