import { Calendar, Messages, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, useParams, withRouter } from 'react-router-dom'
import { useUserContext } from '../Components/StateContext/UserContext'
import 'moment/locale/nb';
import '../CSS/dataTables.dataTables.css';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb'
import OKomTab from '../Components/OsloKommuneUI/OKomTab'
import { PaymentViews } from '../Models/Interfaces'
import ReactDataTables from '../Components/OsloKommuneUI/ReactDataTables'
moment.locale('nb');
const localizer = momentLocalizer(moment)

const PaymentView: React.FC<RouteComponentProps> = (props) => {
  const { setUserInfo, setDepartmentInfo, setToken } = useUserContext();
  const [selectedView, setSelectedView] = useState<PaymentViews>(PaymentViews.Membership);


  const clickRef = useRef(null);
  const tabs = ['Medlemskontigent', 'Alle'];

  const handleTabChange = (activeTab: string) => {

    if (activeTab == "Alle") {
      setSelectedView(PaymentViews.All);
    }
    if (activeTab == "Medlemskontigent") {
      setSelectedView(PaymentViews.Membership);
    }
  };

  useEffect(() => {
    return () => {
      window.clearTimeout(clickRef?.current)
    }
  }, [])
  const data = [
    {
      id: "1",
      name: "Tiger Nixon",
      position: "System Architect",
      salary: "$320,800",
      start_date: "2011/04/25",
      office: "Edinburgh",
      extn: "5421",
    },
    {
      id: "2",
      name: "Garrett Winters",
      position: "Accountant",
      salary: "$170,750",
      start_date: "2011/07/25",
      office: "Tokyo",
      extn: "8422",
    },
  ];
  const columns = [
    { data: "name", title: "Navn" },
    { data: "position", title: "Status" },
  ];

  const onSelecting = useCallback((range: { start: Date; end: Date }): boolean => {
    window.clearTimeout(clickRef?.current)
    clickRef.current = window.setTimeout(() => {
      console.log(range);
    }, 250)
    return true;
  }, [])


  return (
    <>
      <OKomBreadcrumb path='Betalinger'></OKomBreadcrumb>
      <div className="ods-grid">
        <div className="ods-grid__column--12">
          <div className="ods-grid ods-grid--center">
            <div className="ods-grid__column--12">
              <OKomTab tabs={tabs} onTabChange={handleTabChange} />
            </div>
          </div>
          <div className="ods-grid ods-grid--center">
            <div className="ods-grid__column--12">
          <div className='ods-padding-top-11'>

            {selectedView == PaymentViews.Membership &&
              <span>Medlemskap</span>
            }
          </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(PaymentView);
