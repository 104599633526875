import { jwtDecode } from 'jwt-decode';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface UserContextState {
    user: Api.User | null;
    setUserInfo: (user: Api.User | null) => void;
    department: Api.Department | null;
    setDepartmentInfo: (department: Api.Department | null) => void;
    setToken: (jwtToken: string) => void;
    userToken: string | null;
    userRoles: string[];
}

export const UserContext = createContext<UserContextState>({
    user: null,
    setUserInfo: () => { },
    department: null,
    setDepartmentInfo: () => { },
    setToken: () => { },
    userToken: null,
    userRoles: [],
});


export const UserProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<Api.User | null>(null);
    const [userToken, setUserToken] = useState<string | null>(null);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [department, setDepartment] = useState<Api.Department | null>(null);
    const history = useHistory();

    useEffect(() => {
        const userToken = localStorage.getItem('myToken');
        const user = localStorage.getItem('myUser');
        const department = localStorage.getItem('myDepartment');
        const userRoles = localStorage.getItem('myRoles');

        if (department) {
            var dep: Api.Department = JSON.parse(department);
            setDepartment(dep);
        }
        if (userToken) {
            setUserToken(userToken);
            const decoded = jwtDecode(userToken);
            const roles = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            if (roles) {
              const rolesArray = typeof roles === 'string' ? [roles] : roles;
              setUserRoles(rolesArray);
            }
        }

        if (user) {
            var usr: Api.User = JSON.parse(user);
            setUser(usr);
        }

        if (!userToken) {
            if (window.location.href.indexOf('login') === -1) window.location.href = '/login';
            return;
        }


    }, [history]);

    const setToken = (jwtToken: string) => {
        if (jwtToken == null) localStorage.removeItem('myToken')
        else localStorage.setItem('myToken', jwtToken);
    };

    const setDepartmentInfo = (department: Api.Department) => {
        if (department == null) localStorage.removeItem('myDepartment')
        else localStorage.setItem('myDepartment', JSON.stringify(department));
        setDepartment(department)
    };

    const setUserInfo = (user: Api.User) => {
        if (user == null) localStorage.removeItem('myUser')
        else localStorage.setItem('myUser', JSON.stringify(user));

        setUser(user)
    };

    const value = { department, setDepartmentInfo, setToken, user, setUserInfo, userToken, userRoles };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
