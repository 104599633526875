import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom';
import OKomNewUserComponent from '../Components/OsloKommuneUI/OKomNewUserComponent';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb';


const CreateUserView: React.FC<RouteComponentProps> = (props) => {
  const { depId, classId, week } = useParams<{ depId: string; classId: string; week: string }>();

  useEffect(() => {

  }, [depId, classId, week]);

  return (
    <>
     <OKomBreadcrumb path='Opprett bruker'></OKomBreadcrumb>
      <div className="ods-margin-top-11 ods-grid ods-grid--gap ods-grid--center">
        <div className="ods-padding-3 ods-grid__column--12 ods-grid__column--start-4-breakpoint-medium ods-grid__column--end-10-breakpoint-medium">
            <OKomNewUserComponent></OKomNewUserComponent>
        </div>
      </div>
    </>
  );
}

export default withRouter(CreateUserView);
