import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUserContext } from '../Components/StateContext/UserContext';
import RestService from '../Services/RestService';
import OKomButton from '../Components/OsloKommuneUI/OKomButton';
import { jwtDecode } from 'jwt-decode';
import OKomBreadcrumb from '../Components/OsloKommuneUI/OKomBreadcrumb';

const MyUserDetailsView: React.FC = () => {
  const { user, setUserInfo, userToken, department, userRoles } = useUserContext();

  const [mobilePhone, setMobilePhone] = useState<string>(user?.Mobilephone || '');
  const [email, setEmail] = useState<string>(user?.Email || '');
  const [roles, setRoles] = useState<string[]>(userRoles || []);

  useEffect(() => {
    setMobilePhone(user?.Mobilephone || '');
    setEmail(user?.Email || '');
    setRoles(userRoles || []);
  }, [user, userToken]);

  const saveChanges = async () => {
    try {
      const payload : Api.UpdateUserInfoModel = {
        UserId: 0,
        MobilePhone: mobilePhone,
        Email: email,
      };

      var response = await RestService.post<Api.UpdateUserInfoModel,Api.User>('/api/user/UpdateMyInfo', payload);
      toast.success('Lagre info!');
      setUserInfo(response);
    } catch (error) {
      console.error(error);
      toast.error(`Feil med lagring. ${error}`);
    }
  };

  return (
    <>
      <OKomBreadcrumb path='Bruker'></OKomBreadcrumb>
      <div className="ods-margin-top-11-breakpoint-medium ods-grid ods-grid--gap ods-grid--center">
        <div className="ods-grid__column--12 ods-grid__column--6-breakpoint-medium">
          <div className="ods-status-card">
            <div className="ods-status-card__content">
              <h3 className="ods-status-card__heading">Personopplysninger</h3>
              <div className="ods-status-card__actions">
                <div className="ods-input">
                  <label className="ods-input__label">
                    Fornavn
                    <input className="ods-input__input" disabled value={user?.Firstname} type="text" autoComplete="on" placeholder="Fornavn"  style={{ backgroundColor: '#dadada' }} />
                  </label>
                </div>

                <div className="ods-input ods-margin-bottom-13">
                  <label className="ods-input__label">
                    Etternavn
                    <input className="ods-input__input" disabled value={user?.Lastname} type="text" autoComplete="on" placeholder="Etternavn" style={{ backgroundColor: '#dadada' }}  />
                  </label>
                </div>


                <div className="ods-input">
                  <label className="ods-input__label">
                    Mobiltelefon
                    <input onChange={(e) => setMobilePhone(e.target.value)} className="ods-input__input" value={mobilePhone} type="text" autoComplete="on" placeholder="Mobiltelefon" />
                  </label>
                </div>

                <div className="ods-input ods-input ods-margin-bottom-8">
                  <label className="ods-input__label">
                    E-post
                    <input onChange={(e) => setEmail(e.target.value)} className="ods-input__input" value={email} type="text" autoComplete="on" placeholder="E-post" />
                  </label>
                </div>
              </div>
              <OKomButton
                buttonText='Lagre'
                size='medium'
                onClick={() => saveChanges()}
              />
            </div>
          </div>
        </div>

        <div className="ods-grid__column--12 ods-grid__column--6-breakpoint-medium">
          <div className="ods-status-card">
            <div className="ods-status-card__content">
              <h3 className="ods-status-card__heading">Roller og rettigheter</h3>
              <div className="ods-status-card__actions">


              <div className="ods-input ods-margin-bottom-11">
                  <div className='ods-text--size-india'>Brukerid:</div>
                  {userToken &&
                    <div>{jwtDecode(userToken)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]}</div>}
                </div>

                <div className="ods-input ods-margin-bottom-11">
                  <div className='ods-text--size-india'>Roller:</div>
                  {roles.map((role) => (
                    <div key={role} className="">{role}</div>
                  ))}
                </div>

                <div className="ods-input ods-margin-bottom-11">
                  <div className='ods-text--size-india'>Skole:</div>
                  <div>{department?.Name}</div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyUserDetailsView;
